const routes = [
  // {
  //   path: '/',
  //   component: () => import('layouts/MainLayout.vue'),
  //   children: [
  //     { path: '', component: () => import('pages/IndexPage.vue') }
  //   ]
  // },
  {
    path: "/",
    component: () => import("layouts/MainLayout.vue"),
    children: [
      {
        path: ":user_onexfy/config",
        name: "Config",
        component: () => import("pages/Config.vue"),
      },
      {
        path: ":user_onexfy/:price_url",
        name: "Home",
        component: () => import("layouts/main.vue"),
      },
      {
        path: ":user_onexfy/:price_url/:category/:category_id",
        name: "HomeCategory",
        component: () => import("layouts/main.vue"),
      },
      {
        path: ":user_onexfy/:price_url/:category/:category_id/:product/:product_id",
        name: "Product",
        component: () => import("pages/Product.vue"),
      },
    ],
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: "/:catchAll(.*)*",
    component: () => import("pages/ErrorNotFound.vue"),
  },
];

export default routes;
