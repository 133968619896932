export function getInvoice(state) {
  return state.invoice;
}

export function totalItemsInCart(state) {
  let total = 0;
  state.invoice.products.map((data) => {
    total = total + data.quantity;
  });
  return total;
}

export function productFilter(state) {
  return state.productFilter;
}

export function categoryFilter(state) {
  return state.categoryFilter;
}
