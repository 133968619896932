// get path of route
const currentPath = window.location.pathname.replace(/^\/|\/$/g, "");
const path = !currentPath.includes("config")
  ? currentPath.split("/").shift()
  : "";
// return state function
export default function () {
  // get local storage data for fitler config state
  const consfigData = localStorage.getItem("catalog")
    ? JSON.parse(localStorage.getItem("catalog") || {})
    : [];
  // rturn state values
  return {
    config: consfigData.find((data) => {
      return (
        data.user_onexfy.toLowerCase() === path.toLocaleLowerCase() ||
        data.domain.toLocaleLowerCase() === path.toLocaleLowerCase()
      );
    })
      ? consfigData.find((data) => {
          return (
            data.user_onexfy.toLowerCase() === path.toLocaleLowerCase() ||
            data.domain.toLocaleLowerCase() === path.toLocaleLowerCase()
          );
        })
      : {},
  };
}
