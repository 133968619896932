import { getStorage } from "./actions";

export default function () {
  let invoice = {
    products: [],
    channel: "Catálogo",
    price: 0,
    discount: 0,
    discount_porcent: 0,
    utilities: 0,
    delivery: 0,
    total: 0,
    status: false,
    is_pay: "No",
    tax_total: 0,
    taxt: 19,
    subtotal: 0,
    manage_stock: true,
    has_manage: false,
    productFilter: null,
    categoryFilter: null,
  };

  if (getStorage()) {
    invoice = getStorage();
  }

  return {
    invoice,
  };
}
