import { boot } from "quasar/wrappers";
import axios from "axios";
import { Notify } from "quasar";

const api = axios.create({ baseURL: process.env.API_URL });

export default boot(({ app }) => {
  api.interceptors.response.use(
    (response) => {
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      if (error.response.data && error.response.data.error) {
        if (error.response.data.code === 422) {
          const message = error.response.data.error.shift();
          Notify.create({
            message: message ? message : "Ha ocurrido un error",
            type: "negative",
            color: "red-9",
            position: "bottom-right",
          });
        }
      }
      if (error.response.data && error.response.data.message) {
        const message = error.response.data.message;
        Notify.create({
          message: message ? message : "Ha ocurrido un error",
          type: "negative",
          color: "red-9",
          position: "bottom-right",
        });
      }
      return Promise.reject(error);
    }
  );
  app.config.globalProperties.$axios = axios;
  app.config.globalProperties.$api = api;
});

export { api };
