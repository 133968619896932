import { Notify } from "quasar";

export const SendNotify = function (type, message, color) {
  let icon = type === "positive" ? "check_circle_outline" : "warning";
  let colorValidate = color;
  if (type !== "negative") {
    colorValidate = "positive";
  } else {
    colorValidate = "negative";
  }
  Notify.create({
    message: message,
    type: type,
    color: colorValidate,
    position: "bottom-right",
    icon: icon,
    rounded: true,
  });
};

export function formatPrice(total) {
  var val = (total / 1).toFixed(0).replace(".", ",");
  return "$" + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

export function formatStringToUrl(value) {
  let normalizeName = value.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  return normalizeName.toLowerCase().replace(/\s+/g, "_");
}
