import { SendNotify } from "src/utilities/global";
import { setStorage } from "./actions";

// pussh item to cart
export function pushNewItemToCart(state, data) {
  // valdiamos si el producto ya existe en el carrito
  const productIndex = state.invoice.products.findIndex((item) => {
    return item.reference === data.reference;
  });
  if (!state.invoice.products[productIndex]) {
    state.invoice.products.push(data);
    setStorage(state.invoice);
  } else {
    SendNotify("negative", "Ya posees este ítem en el carrito.");
  }
  // recalculamos el total de las lineas
}

// remove item from cart
export function deleteProductFromCart(state, idx) {
  state.invoice.products.splice(idx, 1);
  setStorage(state.invoice);
}

// plus 1 item
export function plusItem(state, idx) {
  if (
    state.invoice.products[idx].quantity <
    state.invoice.products[idx].stock_total
  ) {
    state.invoice.products[idx].quantity++;
  }
  setStorage(state.invoice);
}

export function removeOneItem(state, idx) {
  if (state.invoice.products[idx].quantity > 1) {
    state.invoice.products[idx].quantity--;
    setStorage(state.invoice);
  }
}

// calculate total invoice
export function calculateTotal(state) {
  state.invoice.price = 0;
  state.invoice.total = 0;
  state.invoice.tax_total = 0;
  state.invoice.subtotal = 0;
  state.invoice.products.forEach((item, index) => {
    // get tax and total data
    const taxDecimal = item.tax / 100;
    const taxBase = taxDecimal + 1;
    const total = (item.price * item.quantity).toFixed(2);
    // calculate base and tax
    const subtotal = parseFloat(parseFloat(total) / taxBase).toFixed(2);
    const tax = parseFloat((subtotal * taxDecimal).toFixed(2));
    // set item total
    state.invoice.products[index].subtotal = parseFloat(subtotal);
    state.invoice.products[index].tax_total = parseFloat(tax);
    state.invoice.products[index].total = parseFloat(total);
    // set invoices total
    state.invoice.price += state.invoice.products[index].subtotal;
    state.invoice.total += state.invoice.products[index].total;
    state.invoice.tax_total += state.invoice.products[index].tax_total;
    state.invoice.subtotal += state.invoice.products[index].subtotal;
  });
  setStorage(state.invoice);
}

export function clearData(state) {
  state.invoice = {
    products: [],
    channel: "Catálogo",
    price: 0,
    discount: 0,
    discount_porcent: 0,
    utilities: 0,
    delivery: 0,
    total: 0,
    status: false,
    is_pay: "No",
    tax_total: 0,
    taxt: 19,
    subtotal: 0,
    manage_stock: true,
    has_manage: false,
  };
  setStorage(state.invoice);
}

export function setIdToFilter(state, id) {
  state.productFilter = id;
}

export function setCategoryIdToFilter(state, id) {
  state.categoryFilter = id;
}
